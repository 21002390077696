var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-2 mb-5",attrs:{"align":"center"}},[_c('v-col',[(_vm.userAccess.canCreate)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.$refs.dialogPostageCost.dialog = true}}},[_vm._v(" "+_vm._s(_vm.$_strings.postageCost.add)+" ")]):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"body",attrs:{"loading":_vm.isLoading,"headers":_vm.displayedHeaders,"items":_vm.items,"item-key":"id","server-items-length":_vm.totalItems,"options":_vm.pagination,"footer-props":{
          showCurrentPage: true,
          showFirstLastPage: true,
          disablePagination: _vm.isLoading,
          disableItemsPerPage: _vm.isLoading
        }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatAsCurrency(item.amount)))])]}},{key:"item.createdBy",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.createdBy))]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.dateFormat(item.createdAt)))])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(_vm.userAccess.canUpdate)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","small":"","color":"primary"},on:{"click":function (){ return _vm.edit(item); }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.EDIT))])]):_vm._e(),(_vm.userAccess.canDelete)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"red"},on:{"click":[function (){ return _vm.deletePostageCost(item); },function($event){$event.stopPropagation();$event.preventDefault();}]}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.DELETE))])]):_vm._e()]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.postageCost.pageName)+" "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)],1),_c('DialogPostageCost',{ref:"dialogPostageCost",on:{"fetchPostageCost":_vm.fetchPostageCost}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }