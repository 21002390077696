<template>
  <v-container fluid>
    <v-row
      class="mt-2 mb-5"
      align="center"
    >
      <v-col>
        <v-btn
          color="primary"
          @click="$refs.dialogPostageCost.dialog = true"
          v-if="userAccess.canCreate"
        >
          {{ $_strings.postageCost.add }}
        </v-btn>

      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :loading="isLoading"
          :headers="displayedHeaders"
          :items="items"
          item-key="id"
          :server-items-length="totalItems"
          class="body"
          :options.sync="pagination"
          :footer-props="{
            showCurrentPage: true,
            showFirstLastPage: true,
            disablePagination: isLoading,
            disableItemsPerPage: isLoading
          }">
          <template v-slot:[`item.amount`]={item}>
            <span>{{ formatAsCurrency(item.amount) }}</span>
          </template>
          <template v-slot:[`item.createdBy`]={item}>
            <span>{{ item.createdBy }}</span>
            <br/>
            <span>{{ dateFormat(item.createdAt) }}</span>
          </template>
          <template v-slot:[`item.actions`]="{item}">
            <v-tooltip bottom v-if="userAccess.canUpdate">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  class="mr-2"
                  color="primary"
                  @click="()=>edit(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>{{$_strings.common.EDIT}}</span>
            </v-tooltip>
            <v-tooltip top v-if="userAccess.canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  icon
                  color="red"
                  @click="()=> deletePostageCost(item)"
                  @click.stop.prevent
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </template>
              <span>{{$_strings.common.DELETE}}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`footer.page-text`]="props">
            <span>
              {{$_strings.postageCost.pageName}}
              <span v-if="items.length">
                {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
              </span>
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogPostageCost
      ref="dialogPostageCost"
      @fetchPostageCost="fetchPostageCost"
    />
  </v-container>
</template>

<script>
import {
  dateFormat,
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';
import DialogPostageCost from './Dialog/CreatePostageCost.vue';

export default {
  name: 'postage-cost',
  components: {
    DialogPostageCost,
  },
  data() {
    return {
      isLoading: false,
      items: [],
      headers: [
        {
          text: this.$_strings.postageCost.shippingCostTypeName,
          value: 'shippingCostTypeName',
          sortable: false,
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.postageCost.originLocationName,
          value: 'originLocationName',
          sortable: false,
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.postageCost.destinationLocationName,
          value: 'destinationLocationName',
          sortable: false,
          class: 'white--text primary text-capitalize',
        },
        {
          text: this.$_strings.postageCost.transportTypeName,
          value: 'transportTypeName',
          sortable: false,
          class: 'white--text primary text-capitalize',
          width: '15%',
        },
        {
          text: this.$_strings.postageCost.serviceTypeName,
          value: 'serviceType',
          class: 'white--text primary text-capitalize',
          width: '15%',
        },
        {
          text: this.$_strings.postageCost.amount,
          value: 'amount',
          class: 'white--text primary text-capitalize',
          width: '10%',
        },
        {
          text: this.$_strings.postageCost.createdBy,
          value: 'createdBy',
          class: 'white--text primary text-capitalize',
        },
        {
          text: 'Aksi',
          value: 'actions',
          class: 'white--text primary text-capitalize',
          sortable: false,
          width: '10%',
        },
      ],
      pagination: defaultPagination(),
      totalItems: 0,
    };
  },
  computed: {
    displayedHeaders() {
      const { canUpdate, canDelete } = this.userAccess;
      return this.headers.filter((header) => {
        if (header.value === 'actions' && !canUpdate && !canDelete) return;
        return header;
      });
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        this.fetchPostageCost();
      },
      deep: true,
    },
  },
  methods: {
    dateFormat,
    edit(item) {
      this.$refs.dialogPostageCost.dialog = true;
      this.$refs.dialogPostageCost.form = {
        ...this.$refs.dialogPostageCost.form,
        ...item,
      };
    },
    formatAsCurrency(value) {
      const formatNumber = Intl.NumberFormat('id-ID');
      return formatNumber.format(value) || 0;
    },
    fetchPostageCost() {
      this.isLoading = true;
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        sort: handleSortBy({ sortBy, sortDesc }),
      };
      this.$_services.postageCost.postageCostList(skipEmptyStringObject(filters))
        .then((result) => {
          this.items = result.data.contents;
          this.totalItems = result.data.totalData;
        }).finally(() => {
          this.isLoading = false;
        });
    },
    deletePostageCost(item) {
      this.$dialog.warning({
        text: this.$_strings.postageCost.deletePostageCostConfirmMsg,
        title: this.$_strings.common.WARNING,
        persistent: true,
        actions: {
          false: this.$_strings.common.NO,
          true: this.$_strings.common.YES,
        },
      }).then((userRes) => {
        if (!userRes) return;
        this.$root.$loading.show();
        this.$_services.postageCost.deletePostageCost(item.id)
          .then(() => {
            this.$dialog.notify.success('Berhasil menghapus data biaya ongkos kirim');
            this.items.splice(this.items.indexOf(item), 1);
          }).finally(() => {
            this.$root.$loading.hide();
          });
      });
    },
  },
};
</script>
