<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      width="600"
      scrollable
    >
      <v-card :loading="isLoading">
        <v-card-title class="caption">
          <h3>{{$_strings.postageCost.title}}</h3>
          <v-toolbar-items class="ml-auto">
            <v-btn
              icon
              color="black"
              dark
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col class="mt-5">
                <span class="postcost-input-label">{{$_strings.postageCost.inputNamaBiayaLabel}}</span>
                <v-autocomplete
                  class="mt-2"
                  outlined
                  dense
                  flat
                  :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.postageCost.inputNamaBiayaLabel)]"
                  :items="itemsShippingCost"
                  item-text="name"
                  item-value="id"
                  v-model="form.shippingCostTypeId"
                  :loading="isLoadingShippingCost"
                ></v-autocomplete>
                <v-row>
                  <v-col cols="12">
                    <span class="postcost-input-label">{{$_strings.postageCost.inputServiceType}}</span>
                    <v-select
                      class="mt-2"
                      outlined
                      dense
                      flat
                      :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.postageCost.inputServiceType)]"
                      :items="itemsServiceType"
                      v-model="form.serviceType"
                      :loading="isLoadingServiceType"
                      @change="onChangeService"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row v-if="form.serviceType !== 'LCL'">
                  <v-col cols="12">
                    <span class="postcost-input-label">{{$_strings.postageCost.inputTipeKendaraan}}</span>
                    <v-autocomplete
                      class="mt-2"
                      outlined
                      dense
                      :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.postageCost.inputTipeKendaraan)]"
                      :items="itemsTransport"
                      item-value="transportTypeId"
                      item-text="name"
                      v-model="form.transportTypeId"
                      @change="resetLocations"
                      :loading="isLoadingTransportType"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <span class="postcost-input-label">{{$_strings.postageCost.inputLokasiAsalLabel}}</span>
                    <common-auto-complete-items
                      :type="(form.serviceType && form.transportTypeId) || form.serviceType === 'LCL' ? 'originLocation' : null"
                      searchName="originName"
                      item-value="originLocationId"
                      item-text="originLocationName"
                      dense
                      outlined
                      class="mt-2"
                      :optional="{
                        serviceType: form.serviceType,
                        transportTypeId: form.transportTypeId,
                      }"
                      v-model="form.originLocationId"
                      :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.postageCost.inputLokasiAsalLabel)]"
                      @updateItems="updateOriginLocationItems"
                      @change="originChanged"
                      :loading="isLoadingLocations"
                      :filter="filterOriginLocation"
                      :items="itemsOrigin"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <span class="postcost-input-label">{{$_strings.postageCost.inputLokasiTujuanLabel}}</span>
                    <common-auto-complete-items
                      :type="form.serviceType && mouId && form.originLocationId ? 'mouRoute' : null"
                      searchName="destinationName"
                      item-value="destinationLocationId"
                      item-text="destinationLocationName"
                      dense
                      outlined
                      class="mt-2"
                      :optional="{
                        mouId,
                        serviceType: form.serviceType,
                        originId: form.originLocationId,
                      }"
                      v-model="form.destinationLocationId"
                      :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.postageCost.inputLokasiTujuanLabel)]"
                      @updateItems="updateDestinationLocationItems"
                      :loading="isLoadingLocations"
                      :filter="filterDestinationLocation"
                      :items="itemsDestination"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <p class="postcost-input-label" for="amount">{{$_strings.postageCost.inputBiayaLabel}}</p>
                    <v-text-field
                      class="mt-2"
                      v-mask="{'alias': 'idr', rightAlign: false, placeholder: ''}"
                      outlined
                      dense
                      id="amount"
                      item-value="id"
                      item-text="amount"
                      v-model="formAmmount"
                      :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.postageCost.inputBiayaLabel)]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            class="white--text"
            @click="dialog = false"
          >
            {{$_strings.common.CANCEL}}
          </v-btn>
          <v-btn
            color="primary"
            @click="submit"
            :disabled="isLoading"
          >
            {{form.id ? $_strings.common.EDIT : $_strings.common.SAVE}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  watch: {
    dialog(newVal) {
      if (newVal) {
        if (!this.form.id) {
          this.isFullField = true;
        }
        if (this.form.id) {
          this.fetchLocations();
        }
        if (this.itemsShippingCost.length && this.form.id) {
          this.setFormEdit();
        }
        if (!this.itemsShippingCost.length) this.fetchShippingCostType();
        this.fetchServiceType();
        return;
      }
      this.isFullField = false;
      this.form = {
        shippingCostTypeId: '',
        serviceType: '',
        originLocationId: '',
        destinationLocationId: '',
        transportTypeId: 0,
        amount: '',
      };
      this.itemsServiceType = [];
      this.itemsOrigin = [];
      this.itemsDestination = [];
      this.itemsTransport = [];
      this.filterOriginLocation = {
        page: 0,
        size: 10,
        totalData: 0,
      };
      this.filterDestinationLocation = {
        page: 0,
        size: 10,
        totalData: 0,
      };
      this.$nextTick(() => {
        if (this.$refs.form.reset) this.$refs.form.reset();
      });
    },
  },
  computed: {
    formAmmount: {
      get() {
        const formatNumber = Intl.NumberFormat('id-ID');
        return formatNumber.format(this.form.amount) || null;
      },
      set(newVal) {
        this.form.amount = String(newVal).replaceAll('.', '');
      },
    },
  },
  data() {
    return {
      dialog: false,
      itemsShippingCost: [],
      itemsServiceType: [],
      itemsOrigin: [],
      itemsDestination: [],
      itemsTransport: [],
      valid: false,
      mouId: null,
      isFullField: false,
      form: {
        shippingCostTypeId: '',
        serviceType: '',
        originLocationId: '',
        destinationLocationId: '',
        transportTypeId: 0,
        amount: '',
      },
      isLoading: false,
      isLoadingLocations: false,
      isLoadingTransportType: false,
      isLoadingShippingCost: false,
      isLoadingServiceType: false,
      filterOriginLocation: {
        page: 0,
        size: 25,
        totalData: 0,
      },
      filterDestinationLocation: {
        page: 0,
        size: 25,
        totalData: 0,
      },
    };
  },
  methods: {
    updateOriginLocationItems(items) {
      if (items.length) this.mouId = items[0].mouId;
      this.itemsOrigin = [...this.itemsOrigin, ...items];
    },
    updateDestinationLocationItems(items) {
      this.itemsDestination = [...this.itemsDestination, ...items];
    },
    originChanged() {
      this.form.destinationLocationId = null;
      this.itemsDestination = [];
      this.filterDestinationLocation = {
        page: 0,
        size: 25,
        totalData: 0,
      };
    },
    resetLocations() {
      this.originChanged();
      this.form.originLocationId = null;
      this.itemsOrigin = [];
      this.filterOriginLocation = {
        page: 0,
        size: 25,
        totalData: 0,
      };
    },
    setFormEdit() {
      const findShippingCost = this.itemsShippingCost.find((cost) => cost.name === this.form.shippingCostTypeName);
      const findOrigin = this.itemsOrigin.find((origin) => origin.originLocationName === this.form.originLocationName);
      const findDestination = this.itemsDestination.find((origin) => origin.destinationLocationName === this.form.destinationLocationName);
      const findTransport = this.itemsTransport.find((transport) => transport.name === this.form.transportTypeName);
      if (findShippingCost && !this.form.shippingCostTypeId && !this.isFullField) {
        this.form.shippingCostTypeId = findShippingCost.id;
      }
      if (findTransport && !this.form.transportTypeId && !this.isFullField) {
        this.form.transportTypeId = findTransport.transportTypeId;
      }
      if (findOrigin && !this.form.originLocationId && !this.isFullField) {
        this.form.originLocationId = findOrigin.originLocationId;
      }
      if (findDestination && !this.form.destinationLocationId && !this.isFullField) {
        this.form.destinationLocationId = findDestination.destinationLocationId;
      }
      if (this.itemsTransport.length && this.itemsOrigin.length && this.itemsDestination.length && this.itemsShippingCost.length) {
        this.isFullField = true;
      }
    },
    async onChangeService(value) {
      this.resetLocations();
      if (value === 'LCL') {
        this.form.transportTypeId = 0;
        return;
      }
      this.fetchTransportType();
    },
    async fetchShippingCostType() {
      try {
        this.isLoadingShippingCost = true;
        const res = await this.$_services.postageCost.findPostageCost();
        this.itemsShippingCost = res.data.contents;
        if (this.form.id) this.setFormEdit();
      } finally {
        this.isLoadingShippingCost = false;
      }
    },
    async fetchServiceType() {
      try {
        this.isLoadingServiceType = true;
        const res = await this.$_services.postageCost.getServiceType();
        this.itemsServiceType = res.data;
        if (this.form.id && !this.isFullField) {
          this.setFormEdit();
          this.onChangeService(this.form.serviceType, this.mouId);
        }
      } finally {
        this.isLoadingServiceType = false;
      }
    },
    async fetchTransportType() {
      try {
        this.isLoadingTransportType = true;
        if (this.isFullField) {
          this.form.transportTypeId = 0;
          this.resetLocations();
          this.itemsTransport = [];
        }
        const result = await this.$_services.postageCost.getTransportType(this.form.serviceType);
        this.itemsTransport = result.data;
        if (this.form.id && !this.isFullField) {
          this.setFormEdit();
        }
      } finally {
        this.isLoadingTransportType = false;
      }
    },
    async fetchLocations() {
      try {
        this.isLoadingLocations = true;
        const {
          originLocationName: originName, destinationLocationName: destinationName, serviceType, transportTypeId,
        } = this.form;
        const filtersOrigin = {
          page: 0,
          size: 10,
          originName,
        };
        const filtersDestination = {
          page: 0,
          size: 10,
          destinationName,
        };
        const originItems = await this.$_services.mou.getOriginLocation(filtersOrigin, { serviceType, transportTypeId });
        const { mouId, originLocationId: originId } = originItems.data.contents[0];
        const destinationItems = await this.$_services.mou.getMouRoute(filtersDestination, { mouId, serviceType, originId });
        this.itemsOrigin = originItems.data.contents;
        this.itemsDestination = destinationItems.data.contents;
        if (this.form.id && !this.isFullField) {
          this.setFormEdit();
        }
      } finally {
        this.isLoadingLocations = false;
      }
    },
    submit() {
      if (!this.$refs.form.validate()) return;
      this.isLoading = true;
      this.$_services.postageCost.postageCostAddEdit(this.form, this.form.id)
        .then(() => {
          if (this.form.id) {
            this.$dialog.notify.success(this.$_strings.postageCost.editPostageCostConfirmMsg);
          } else {
            this.$dialog.notify.success(this.$_strings.postageCost.successUpdatePostageCostrMsg);
          }
          this.$emit('fetchPostageCost');
          this.dialog = false;
        }).finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
